import { FunctionComponent } from 'react'
import { SidebarTypes } from '../interfaces/store/appStore'
import FilterConnectionsForm from './forms/FilterConnectionsForm'
import FormTitleContainer from './FormTitleContainer'
import CreateWorkspaceForm from './forms/CreateWorkspaceForm'
import ConnectionSidebarFragment from './ConnectionSidebarFragment'
import { IConnection } from '../interfaces/models/connection'
import CreateEditTagForm from './forms/CreateEditTagForm'
import { ITag } from '../interfaces/models/tag'
import ApplyRemoveTagForm from './forms/ApplyRemoveTagForm'
import { ITask } from '../interfaces/models/task'
import CreateEditTaskForm from './forms/CreateEditTaskForm'
import { INote } from '../interfaces/models/note'
import CreateEditNoteForm from './forms/CreateEditNoteForm'
import { ICustomField } from '../interfaces/models/customFields'
import CreateEditCustomFieldForm from './forms/CreateEditCustomFieldForm'
import FilterNotesForm from './forms/FilterNotesForm'
import FilterTagsForm from './forms/FilterTagsForm'
import { IWorkspace } from '../interfaces/models/workspace'
import FilterTasksForm from './forms/FilterTasksForm'
import SaveFilterForm from './forms/SaveFilterForm'
import CreateEditTemplateForm from './forms/CreateEditTemplateForm'
import BulkRemoveTagForm from './forms/BulkRemoveTagForm'
import { useAuthStore } from '../stores/auth/useAuthStore'
import PabblyIntegrationForm from './forms/PabblyIntegrationForm'
import HubspotIntegrationForm from './forms/HubspotIntegrationForm'
import { TriggerObject } from '../interfaces/models/hubspot'

interface ISidebarRenderProps {
    sidebarType: string,
    connection: IConnection,
    selectedConnectionsIds: string[],
    workspace: IWorkspace,
    invite: boolean,
    tag: ITag,
    note: INote,
    template: INote,
    duplicate?: boolean,
    task: ITask,
    customField: ICustomField,
    formType: string,
    events?: any,
    webhook?: any,
    editTrigger?: TriggerObject,
    onCloseHandler: () => void
    onCreateHandler: () => void
    onSaveHandler?: (e: any) => {},
}

const SidebarRender: FunctionComponent<ISidebarRenderProps> = ({ sidebarType, ...props }) => {
    const { store: { workspace: { showAutoTags }}} = useAuthStore()
    let sidebarToShow
    //todo: add type since we only have so many types of sidebar, title property on all the filter forms should be equal in sizes and colors
    //it is also redundant to be in the form. Form is used here since the name sidebar makes the whole form unusable if we were to use it somewhere else

    switch (sidebarType) {
        //BULK MESSAGE
        case SidebarTypes.BULK_MESSAGE:
            const { onSaveHandler } = props
            sidebarToShow = <FormTitleContainer title='Message' label='Write a message you wish to send in the box below. You can also use First and Last name placeholders to get the actual data from a LinkedIn Connection by clicking on the buttons below.'><CreateEditTemplateForm onMessageSaveHandler={onSaveHandler} {...props} /></FormTitleContainer>
            break

        //FILTERING
        case SidebarTypes.CONNECTION_FILTER:
            sidebarToShow = <FormTitleContainer title='Filter'><FilterConnectionsForm /></FormTitleContainer>
            break

        case SidebarTypes.NOTE_FILTER:
            sidebarToShow = <FormTitleContainer title={'Filter'}><FilterNotesForm /></FormTitleContainer>
            break

        case SidebarTypes.TASKS_FILTER:
            sidebarToShow = <FormTitleContainer title={'Filter'}><FilterTasksForm /></FormTitleContainer>
            break

        //CREATE AND UPDATE
        case SidebarTypes.WORKSPACE_CREATE:
            const { workspace, invite } = props
            sidebarToShow = <FormTitleContainer title={invite ? 'Add member(s)' : workspace ? 'Edit workspace' : 'Create workspace'}><CreateWorkspaceForm {...props} /></FormTitleContainer>
            break

        case SidebarTypes.TAG_EDIT_CREATE:
            const { tag } = props
            sidebarToShow = <FormTitleContainer title={tag ? 'Edit tag' : 'Create tag'}><CreateEditTagForm tag={tag} /></FormTitleContainer>
            break

        case SidebarTypes.NOTE_EDIT_CREATE:
            sidebarToShow = <FormTitleContainer title={'Edit Notes'}><CreateEditNoteForm {...props} /></FormTitleContainer>
            break

        case SidebarTypes.TASK_EDIT_CREATE:
            const { task, formType } = props
            sidebarToShow = <FormTitleContainer title={formType?.length > 0 && formType !== 'linked connection' ? `Set ${formType}` : task ? 'Edit Task' : 'Create New Task'} label='Never forget to follow up, or reach out to a contact.'><CreateEditTaskForm {...props} /></FormTitleContainer>
            break

        case SidebarTypes.TEMPLATES_EDIT_CREATE:
            const { template, duplicate } = props
            sidebarToShow = <FormTitleContainer title={(template && duplicate) ? 'Duplicate Template' : template ? 'Edit Template' : 'Create New Template'}><CreateEditTemplateForm {...props} /></FormTitleContainer>
            break

        case SidebarTypes.CUSTOM_FIELD_EDIT_CREATE:
            const { customField } = props
            sidebarToShow = <FormTitleContainer title={customField ? 'Edit Field' : 'Create New Field'} label='Add fields that you wish to add to connections.'><CreateEditCustomFieldForm customField={customField} /></FormTitleContainer>
            break

        //SHOW, CREATE AND UPDATE
        case SidebarTypes.CONNECTION_ACTIONS:
            sidebarToShow = <ConnectionSidebarFragment {...props} />
            break

        case SidebarTypes.APPLY_TAG: {
            const { connection } = props
            sidebarToShow = <FormTitleContainer title={connection ? 'Edit tags' : 'Bulk apply tags'}><ApplyRemoveTagForm tags={!connection ? [] : showAutoTags ? connection?.tags : connection?.tags?.filter(tag => !tag.isForbidden)?.sort((a, b) => a?.title?.localeCompare(b?.title))} {...props}/></FormTitleContainer>
            break
            }

        case SidebarTypes.REMOVE_TAG: {
            sidebarToShow = <FormTitleContainer title={'Bulk remove tags'}><BulkRemoveTagForm {...props}/></FormTitleContainer>
            break
            }

        case SidebarTypes.SAVE_FILTER:
            sidebarToShow = <FormTitleContainer title={'Save filter'}><SaveFilterForm /></FormTitleContainer>
            break

        case SidebarTypes.TAG_FILTER:
            sidebarToShow = <FormTitleContainer title={'Filter'}><FilterTagsForm /></FormTitleContainer>
            break

        //INTEGRATION
        case SidebarTypes.PABBLY:
            const { webhook } = props
            sidebarToShow = <FormTitleContainer title={webhook ? 'Edit Pabbly Webhook' : 'Create Pabbly Webhook'}><PabblyIntegrationForm {...props}/></FormTitleContainer>
            break
        case SidebarTypes.HUBSPOT:
            const { editTrigger } = props
            sidebarToShow = <FormTitleContainer title={editTrigger ? 'Edit trigger' : 'New trigger'}><HubspotIntegrationForm {...props}/></FormTitleContainer>
            break

        case 'testSecond':
            sidebarToShow = <div>ACO GIGANT</div>
            break
    }

    return <>{sidebarToShow}</>

}

export default SidebarRender
