import { FunctionComponent, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import TablePageNavigation from '../../../../components/TablePageNavigation'
import Row from '../../../../components/Row'
import FilterButton from '../../../../fragments/FilterButton'
import { SidebarTypes } from '../../../../interfaces/store/appStore'
import Button from '../../../../components/Button'
import { useTagsStore } from '../../../../stores/tags/useTagsStore'
import { useTagsHandlers } from '../../../../services/actions/tags/useTagsHandlers'
import { useAppHandlers } from '../../../../services/actions/app/useAppHandlers'
import DropdownItem from '../../../../components/dropdown/DropdownItem'
import { useAuthStore } from '../../../../stores/auth/useAuthStore'
import { styles } from '../../../../styles/themes/style'
import DropdownMenu from '../../../../components/dropdown/DropdownMenu'
import TagsBulkActionsDropdown from '../../../../fragments/dropdowns/TagsBulkActionsDropdown'
import ConnectionsSyncProgress from '../../../../fragments/ConnectionsSyncProgress'
import Column from '../../../../components/Column'

interface ITagControlBarProps {
    selectedTags?: any
    setSelectedTags?: (e: any) => void
    isAllSelected?: boolean
    setIsAllSelected?: (e: boolean) => void
    onProgressHidden?: () => void;
}

const TagControlBar: FunctionComponent<ITagControlBarProps> = ({ selectedTags, setSelectedTags, setIsAllSelected, onProgressHidden }) => {
    const { store: { tagsFilter, tagsParameters } } = useTagsStore()
    const { store: { workspace} } = useAuthStore()
    const { setTagsParametersHandler, getTotalPaginatedTags, numberOfTagsFiltersHandler, setClearTagsFilterHandler, updateTagsAccessHandler, deleteTagsHandler } = useTagsHandlers()
    const { openSidebar } = useAppHandlers()

    useEffect(() => {
        getTags()
    }, [tagsFilter, tagsParameters?.page, tagsParameters?.pageSize])

    const getTags = useCallback(async () => {
        await getTotalPaginatedTags()
    }, [tagsFilter, tagsParameters?.page, tagsParameters?.pageSize])

    const resetFilterHandler = useCallback(() => {
        setClearTagsFilterHandler();
    }, [tagsFilter, setClearTagsFilterHandler]);

    const onClickHandler = async (isPrivate: boolean) => {
        await updateTagsAccessHandler( { selectedTags: selectedTags, private: isPrivate})
        setIsAllSelected && setIsAllSelected(false)
        setSelectedTags && setSelectedTags([])
    }

    return <Column>
        <Row flexDirection={'row-reverse'}>
                <ConnectionsSyncProgress onProgressHidden={onProgressHidden} />
        </Row>
        <Row gap='20px' alignItems spaceBetween flexWrap>
            <Row gap="12px">
                <Button $type='blue' $bigButton onClickHandler={() => openSidebar(SidebarTypes.TAG_EDIT_CREATE)}>{'Add New Tag'}</Button>
                {workspace.isBusiness &&
                    <ShowDesktop>
                        <StyledDropdownMenu disabled={(selectedTags.length === 0)} title={'Update Access'}>
                            <DropdownItem onClickHandler={() => onClickHandler(true)}>Private</DropdownItem>
                            <DropdownItem onClickHandler={() => onClickHandler(false)}>Public</DropdownItem>
                        </StyledDropdownMenu>
                        </ShowDesktop>
                }
                <Divider />
                {workspace.isBusiness ? (
                    <ShowDesktop>
                        <Button $type='white' $SVGtype='trashCan' $bigButton $color={styles.colors.red600}
                                disabled={(selectedTags.length === 0)}
                                onClickHandler={() => deleteTagsHandler(selectedTags?.map((tag: any) => tag?._id))}
                            >{'Delete Tag'}
                        </Button>
                    </ShowDesktop>
                ) : (
                    <Button $type='white' $SVGtype='trashCan' $bigButton $color={styles.colors.red600}
                                disabled={(selectedTags.length === 0)}
                                onClickHandler={() => deleteTagsHandler(selectedTags?.map((tag: any) => tag?._id))}
                            >{'Delete Tag'}
                    </Button>
                )}
                {workspace.isBusiness && 
                    <ShowMobile>
                        <TagsBulkActionsDropdown 
                            selectedTags={selectedTags} 
                            deleteTagsHandler={deleteTagsHandler} 
                            updateAccessHandler={onClickHandler}/>
                    </ShowMobile>
                }
            </Row>
            <Row gap='12px' alignItems>
                <div style={{whiteSpace: 'nowrap'}}>
                    <TablePageNavigation 
                        page={tagsParameters?.page ?? 1}
                        pageSize={tagsParameters?.pageSize ?? 25}
                        totalCount={tagsParameters?.total ?? 0}
                        onPageChanged={(value: number) => setTagsParametersHandler({ ...tagsParameters, page: value })}
                        onPageSizeChanged={(value: number) => setTagsParametersHandler({ ...tagsParameters, page: 1, pageSize: value })}
                    />
                </div>
                <FilterButton 
                    numberOfFilters={numberOfTagsFiltersHandler()} 
                    sidebar={SidebarTypes.TAG_FILTER}
                    resetFilter={() => resetFilterHandler()}/>
            </Row>
        </Row>
    </Column>
}

const StyledDropdownMenu = styled(DropdownMenu)`
    height: 34px;
    background-color: ${styles.colors.white};

    & .dropdown-toggle {
        padding: 0 7px;
    }
    
    .actions-dropdown + div{
        min-width: 128px;
    }
`
const ShowMobile = styled.div`
    display: none;
    @media (max-width: 575px) {
        display: block;
    }
`
const ShowDesktop = styled.div`
    display: none;
    @media (min-width: 576px) {
        display: block;
    }
`
const Divider = styled.div`
    height: 18px;
    width: 1px;
    margin-top: 8px;
    background: ${({ theme: { quinaryColor } }) => `${quinaryColor}`};

    @media (max-width: 575px) {
        display: none;
    }
`

export default TagControlBar