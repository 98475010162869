import { FunctionComponent, useState } from "react";
import styled, { useTheme } from "styled-components";
import DropdownMenu from "../components/dropdown/DropdownMenu";
import DropdownItem from "../components/dropdown/DropdownItem";
import Text from "../components/Text";
import Column from "../components/Column";
import Input from "../components/Input";
import { DefaultTheme } from "styled-components";


interface IAdvancedSearchDropdownProps {
    placeholder?: string
    options?: any
    value?: any
    onClick?: (e: any) => void
}

const AdvancedSearchDropdown: FunctionComponent<IAdvancedSearchDropdownProps> = ({placeholder, options, value, onClick}) => {
    const [searchTerm, setSearchTerm] = useState<string>('')
    const theme: DefaultTheme = useTheme();

    const onClickHandler = (e: any) => {
        setSearchTerm('')
        onClick && onClick(e)
    }

    return (
        <StyledDropdownMenu
            title={<StyledColumn justifyCenter><Text $black>{value?.label}</Text></StyledColumn>}
            selectStyle
        > 
            <InputWrapper>
                <Input placeholder={placeholder} prefilledValue={searchTerm} onChangeHandler={(e: any) => setSearchTerm(e)}/>
            </InputWrapper>
            <OptionsWrapper>
            {options?.filter((option: any) => option?.label?.toLowerCase().startsWith(searchTerm?.toLowerCase()))
                    .map((option: any) => (
                        <DropdownItem $color={theme.primaryColor} onClickHandler={(e: any) => onClickHandler(option)}>{option?.label}</DropdownItem>
                    ))}
            </OptionsWrapper>

        </StyledDropdownMenu>
    )

}
const StyledColumn = styled(Column)`
    width: 100%;
    height: 30px;
    padding-left: 6px;
`
const StyledDropdownMenu = styled(DropdownMenu)`
    .actions-dropdown + div {
        width: 100%;
        max-height: 350px;
        overflow: hidden !important;
    }
`
const InputWrapper = styled.div`
    margin: 8px 16px;
`
const OptionsWrapper = styled.div`
    overflow-y: auto;
    max-height: 300px;
`
export default AdvancedSearchDropdown