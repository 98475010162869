import { FunctionComponent, useCallback } from 'react'
import Row from '../../../../components/Row'
import { SidebarTypes } from '../../../../interfaces/store/appStore'
import Button from '../../../../components/Button'
import { useAppHandlers } from '../../../../services/actions/app/useAppHandlers'
import { useTemplateStore } from '../../../../stores/templates/useTemplateStore'
import TablePageNavigation from '../../../../components/TablePageNavigation'
import Input from '../../../../components/Input'
import { useTemplateHandlers } from '../../../../services/actions/template/useTemplateHandlers'
import debounce from 'lodash.debounce'
import { useAuthStore } from '../../../../stores/auth/useAuthStore'
import { useAuthHandlers } from '../../../../services/actions/auth/useAuthHandlers'
import Column from '../../../../components/Column'
import ConnectionsSyncProgress from '../../../../fragments/ConnectionsSyncProgress'

interface ITemplatesControlBarProps {
    onProgressHidden?: () => void;
}

const TemplatesControlBar: FunctionComponent<ITemplatesControlBarProps> = ({onProgressHidden}) => {
    const { openSidebar } = useAppHandlers()
    const { saveUserPreferencesHandler } = useAuthHandlers()
    const { setTemplatesFiltersHandler, setTemplatesParametersHandler, getTemplatesHandler } = useTemplateHandlers()

    const { store: { templatesParameters, templatesFilters } } = useTemplateStore()
    const { store: { user } } = useAuthStore()

    const { page, pageSize, total } = templatesParameters

    const searchByNameHandler = useCallback(debounce((value: string) => { 
        setTemplatesFiltersHandler({...templatesFilters, searchByName: value})
    }, 500), [templatesFilters])

    const isDefaultTemplates = user?.preferences?.hideDefaultTemplates

    const onHideDefaultTemplatesHandler = async () => {
        await saveUserPreferencesHandler({hideDefaultTemplates: !isDefaultTemplates})
        getTemplatesHandler()
    }

    return <Column>
        <Row flexDirection={'row-reverse'}>
            <ConnectionsSyncProgress onProgressHidden={onProgressHidden}/>
        </Row>
        <Row gap='20px' alignItems spaceBetween flexWrap>
            <Row gap="12px">
                <Button $type='blue' $bigButton onClickHandler={() => openSidebar(SidebarTypes.TEMPLATES_EDIT_CREATE, { formType: 'template' })}>{'Add new template'}</Button>
                <Button $type='white' $extraBigButton onClickHandler={onHideDefaultTemplatesHandler}>{`${isDefaultTemplates ? 'Show' : 'Hide'} default templates`}</Button>
            </Row>
            <Row gap='12px'>
                <TablePageNavigation
                    page={page ?? 1}
                    pageSize={pageSize ?? 25}
                    totalCount={total}
                    onPageChanged={(value: number) => setTemplatesParametersHandler({...templatesParameters, page: value})}
                    onPageSizeChanged={(value: number) => setTemplatesParametersHandler({...templatesParameters, pageSize: value})}
                />
                <div style={{ width: 'auto' }}><Input placeholder="Search template name ..." onChangeHandler={(e: any) => searchByNameHandler(e)} /></div>
            </Row>
        </Row>
    </Column>
}

export default TemplatesControlBar