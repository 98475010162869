import { IAutoTag, IConnection } from "../../../interfaces/models/connection";
import { ICreateTag } from "../../../interfaces/models/tag";
import { TConnectionsFilterState, TConnectionsParametersState } from "../../../interfaces/store/connectionStore";
import { useConnectionsActions } from "../../../stores/connections/useConnectionsActions";
import { useConnectionsStore } from "../../../stores/connections/useConnectionsStore";
import { IConnectionProfileId, IConnectionsQueryParams, IWorkspaceSyncStatus, useConnectionsRequest } from "../../apis/connections/useConnectionsRequest";
import { SidebarTypes } from "../../../interfaces/store/appStore";
import { useAppHandlers } from "../app/useAppHandlers";
import { useExtensionRequest } from "../../apis/extension/useExtensionRequest";
import { useAppActions } from "../../../stores/application/useAppActions";
import { useAnalyticsHandlers } from "../analytics/useAnalyticsHandlers";
import { useAuthActions } from "../../../stores/auth/useAuthActions";

export const useConnectionsHandlers = () => {

    const { getConnections, getConnectionsTotal, getConnectionByProfileId, getMultipleConditionsFilters, applyTagsSingleConnection, removeTagsSingleConnection, applyTagsToConnections, removeTagsFromConnections, getExceededDailyLimit, updateProfileFailed, updateProfile, updateConnection, exportToCSV, toggleBulkHideConnections, removeConnection, removeConnections, getWorkspaceSyncStatus } = useConnectionsRequest();
    const { setConnectionsFilter, setConnectionsParameters, setConnections, setConnectionFilterOptions, resetConnectionsPagination, resetConnectionsStore} = useConnectionsActions();
    const { store: { connectionsFilter, connections, connectionsParameters }} = useConnectionsStore()
    const { openSidebar, showSuccessToast, showErrorToast, showInfoToast, showReSendMessageToast } = useAppHandlers()
    const { setRequestLoading } = useAppActions()
    const { getProfileContactInfo, getProfileView, getProfileExtraData, messageProfile, followProfile, unfollowProfile, disconnectProfile } = useExtensionRequest()
    const {incrementDiconnectedConnectionsCountAnalyticsHandler} = useAnalyticsHandlers();
    const { setDisconnectedConnectionsCount} = useAuthActions()

    const getConnectionsHandler = async (params: object = {}) => {
        const mappedParams = getMappedParameters(connectionsParameters, connectionsFilter)

        const result = await getConnections({...mappedParams, ...params}, "getConnectionsPaginate")
        setConnections(result?.connections ?? [])

        const total = await getConnectionsTotal(mappedParams)

        const localStorageColumnsSetup = localStorage.getItem('columns-setup')

        if(!localStorageColumnsSetup) localStorage.setItem('columns-setup', JSON.stringify(connectionsParameters.columnsSetup))
            
        let columnsSetup = localStorageColumnsSetup ? JSON.parse(localStorageColumnsSetup) : connectionsParameters.columnsSetup
        
        setConnectionsParametersHandler({
            total: total ?? 0,
            columnsSetup: columnsSetup
        })
    };

    const getMultipleConditionsFiltersHandler = async (params: object = {}) => {
        const mappedParams = getMappedParameters(connectionsParameters, connectionsFilter)

        const result = await getMultipleConditionsFilters({...mappedParams, ...params})
        if(result) setMultipleConditionsFiltersHandler(result)
    };

    const setClearConnectionsFilterHandler = () => {
       resetConnectionsPagination();
    }

    const setConnectionsFilterHandler = async (payload: TConnectionsFilterState) => {
        setConnectionsFilter(payload);
        setConnectionsParametersHandler({
            ...connectionsParameters,
            page: 1
        });
    }

    const setConnectionsParametersHandler = async (payload: TConnectionsParametersState | { total?: number, columnsSetup?: any, page?: number }) => {
        setConnectionsParameters(payload);
    }

    const setMultipleConditionsFiltersHandler = async (payload: IConnectionsQueryParams) => {
        const filteringOptions = {
            tagFilterOptions: payload.tags ?? [], 
            languageFilterOptions: payload.languages ?? [],
            excludeTagFilterOptions: payload.excludeTags ?? [],
            autotagsFilterOptions: payload.tags?.filter(tag => tag.isForbidden)
            //autotagsFilterOptions: payload.autoTagsArray ? payload.autoTagsArray.flatMap(item => item.autoTags) : [],
        };
        setConnectionFilterOptions(filteringOptions);
    }

    //this is a method to be used 
    const applyRemoveTagsToConnection = async (connectionId: string, applytags: (string | ICreateTag)[], removeTags: string[]) => {
        let result: any
        if(applytags.length) result = await applyTagsSingleConnection(connectionId, {tags: applytags}, false);
        if(removeTags.length) result = await removeTagsSingleConnection(connectionId, {tags: removeTags}, false);
        //this coult be done better to check for both and only then show the toast, but it's good for now
        if(result) {
            showSuccessToast({message: "Tags updated successfully."})
            setConnections(connections.map((connection: IConnection) => {
                if(connection._id === connectionId){
                    return ({...result, users: connection.users, autoTagsArray: connection.autoTagsArray}); // preserve users and autoTagsArray
                }
                return connection;
            }))
            await getMultipleConditionsFiltersHandler();
            return result;
        }
        else{
            showErrorToast({message: "Error applying tags."})
            return undefined;
        }
    }

    const bulkApplyTags = async (applytags: (string | ICreateTag)[], connectionsIds: string[]) => {
        const filters = connectionsIds.length ? {connectionIds: connectionsIds} :
            getMappedParameters(undefined, connectionsFilter)
        const result = await applyTagsToConnections(
            {
                filters: {...filters},
                tags: applytags 
            },
            false
        );
        if(result?.connections) {
            showSuccessToast({message: "Tags applied."});
            setConnections(connections.map((connection: IConnection) => {
                const updatedConn = result?.connections.find((x: IConnection) => x._id === connection._id);
                return updatedConn ? {...updatedConn, users: connection.users, autoTagsArray: connection.autoTagsArray} : connection;
            }));
            await getMultipleConditionsFiltersHandler();
            return result;
        }
        else{
            showErrorToast({message: "Error applying tags."})
            return undefined;
        }
      };

    const bulkRemoveTags = async (removeTags: (string | ICreateTag)[], connectionsIds: string[]) => {
        const filters = connectionsIds.length ? {connectionIds: connectionsIds} :
            getMappedParameters(undefined, connectionsFilter)
        const result = await removeTagsFromConnections(
            {
                filters: {...filters},
                tags: removeTags 
            },
            false
        );
        if(result?.connections) {
            showSuccessToast({message: "Tags removed."});
            setConnections(connections.map((connection: IConnection) => {
                const updatedConn = result?.connections.find((x: IConnection) => x._id === connection._id);
                return updatedConn ? {...updatedConn, users: connection.users, autoTagsArray: connection.autoTagsArray} : connection;
            }));
            await getMultipleConditionsFiltersHandler();
            return result;
        }
        else{
            showErrorToast({message: "Error removing tags."})
            return undefined;
        }
      };
    
    const getConnectionByProfileIdHandler = async (_id: IConnectionProfileId) => {
        const connection = await getConnectionByProfileId(_id)
        if(connection) openSidebar(SidebarTypes.CONNECTION_ACTIONS, { connection: connection })
    }

    const updateConnectionHandler = async (connection: IConnection) => {
        setRequestLoading({ [`updateConnection-${connection?._id}`]: true })
        const { profileId, publicIdentifier } = connection
        if(!profileId)  {
            setRequestLoading({ [`updateConnection-${connection?._id}`]: false })
            return undefined;
        }
        const updateData = await getExceededDailyLimit()
        if(updateData?.data?.update) {
            setRequestLoading({ [`updateConnection-${connection?._id}`]: false })
            showErrorToast({message: 'There was a problem updating your connection. Please check your daily limit in the Settings.'})
            return undefined;
        }

        let profileContactInfo = await getProfileContactInfo({ profileId })
        let profileDetails = await getProfileView({ profileId })
        let profileExtraData = publicIdentifier ? await getProfileExtraData({ profileId: publicIdentifier }) : undefined

        if(profileContactInfo?.error || profileDetails?.error) {//TODO: revisit this
            await updateProfileFailed(profileId)
            setRequestLoading({ [`updateConnection-${connection?._id}`]: false })
            return undefined;
        }
        let profileData = {
            ...profileContactInfo.result,
            ...profileDetails.result
        }
        if(!profileExtraData?.error) profileData = { ...profileData, ...profileExtraData.result }
        const result = await updateProfile(profileId, profileData)
        setRequestLoading({ [`updateConnection-${connection?._id}`]: false })
        await getConnectionsHandler();
        showSuccessToast({message: 'Connection successfully updated'});
        return result
    }

    const sendMessageToConnectionsHandler = async (selectedConnections: IConnection[], message: string) => {
        //disable send message button
        setRequestLoading({ [`sendBulkMessages`]: true })
        showInfoToast({ message: `Please do not refresh your screen.`, duration: 5500})

        let date = new Date()
        let dateString = date.toISOString()
        let sentMessageCount = 0
        let sentToConnections: any[] = []

        for (const connection of selectedConnections) {
            let messageReplacedPlaceholders = message.replace(/{{firstName}}/g, connection.firstName).replace(/{{lastName}}/g, connection.lastName).replace(/{{company}}/g, connection.company ? connection.company : 'your company').trim()
            
            //TODO: not send it as a bulk
             let messageProfileResponse = connection.profileId && messageReplacedPlaceholders?.length ? await messageProfile(connection.profileId, messageReplacedPlaceholders) : undefined
            if (messageProfileResponse) {
                sentToConnections = [...sentToConnections, connection]
                const result = await updateConnection(
                    connection._id,
                    { message: messageReplacedPlaceholders,
                        lastMessaged: {
                            sent: true,
                            lastMessagedAt: dateString
                    }}
                )
                if(result) setConnections(connections.map((connection: IConnection) => {
                    if(connection._id === result._id) return ({ ...result })
                    return connection
                }))
                sentMessageCount++
            }
            
            showInfoToast({ message: `Please do not refresh your screen. ${sentMessageCount} of ${selectedConnections.length} ${selectedConnections.length > 1 ? 'messages' : 'message'} sent.`, duration: 5500 })

            const randomDelay = Math.floor(Math.random() * (5500 - 4500 + 1)) + 4500 // Random delay between 4.5s and 5.5s
            await new Promise(resolve => setTimeout(resolve, randomDelay))
        }

        if(selectedConnections?.length === sentMessageCount) {
            showSuccessToast({ message:`${sentMessageCount} ${sentMessageCount > 1 ? 'messages' : 'message'} successfully sent.` })
        } else {
            const result = selectedConnections.filter(selectedConnection => !sentToConnections.some(obj => obj._id === selectedConnection._id))
            const messageToSend = message
            showReSendMessageToast({ 
                message:`${selectedConnections?.length - sentToConnections.length} ${selectedConnections?.length - sentToConnections.length > 1 ? 'messages' : 'message'} not sent.`,
                duration: 25000,
                onClick: {
                    onClickHandler: async () => await sendMessageToConnectionsHandler(result, messageToSend),
                    label: 'Resend'
                }
            })
        }
        setRequestLoading({ [`sendBulkMessages`]: false })
    }

    const followProfileHandler = async (connection: IConnection) => {
        const { profileId } = connection
        if(!profileId) return undefined;
        const follow = await followProfile(profileId)
        const result = follow && await updateConnection(
            connection._id,
            { isFollowing: true }
        )
        if(result) setConnections(connections.map((connection: IConnection) => {
            if(connection._id === result._id) return ({...result, users: connection.users, autoTagsArray: connection.autoTagsArray})
            return connection
        }))
        return result
    }

    const unfollowProfileHandler = async (connection: IConnection) => {
        const { profileId } = connection
        if(!profileId) return undefined;
        const follow = await unfollowProfile(profileId)
        const result = follow && await updateConnection(
            connection._id,
            { isFollowing: false }
        )
        if(result) setConnections(connections.map((connection: IConnection) => {
            if(connection._id === result._id) return ({...result, users: connection.users, autoTagsArray: connection.autoTagsArray})
            return connection
        }))
        return result
    }

    const disconnectConnectionHandler = async (connection: IConnection, disconnectFromLI: boolean, removeFromLD: boolean) => {
        if(disconnectFromLI) {
            const { profileId } = connection
            if(!profileId) {
                showErrorToast({message: 'There was a problem disconnecting your connections.'})
                return;
            }
            const res = await disconnectProfile(profileId);
            if(!res){
                showErrorToast({message: 'There was a problem disconnecting your connections.'})
                return;
            }
            const disconnectedAnalytic = await incrementDiconnectedConnectionsCountAnalyticsHandler({value: 1}) // it does not matter if it successfully disconnected
            if(disconnectedAnalytic) setDisconnectedConnectionsCount(disconnectedAnalytic.value);
        }
        const result = await removeConnection(connection._id, removeFromLD);
        if(result?.success) await getConnectionsHandler();
        return result;
    }

    const bulkDisconnectConnectionHandler = async (connections: IConnection[], disconnectFromLI: boolean, removeFromLD: boolean, allSelected: boolean) => {
         if(disconnectFromLI) {
            try {
                const connectionsConnectedToLinkedin = connections?.filter(item => !(item?.imported || item?.notConnectedLinkedin)); 
                const promiseArray: any = []
                connectionsConnectedToLinkedin.forEach((connection: IConnection) => {
                    if(!connection.isDisconnected && connection.profileId) {
                    promiseArray.push(disconnectProfile(connection.profileId))                   
                    }
                })
                const disconnectedAnalytic = await incrementDiconnectedConnectionsCountAnalyticsHandler({value: promiseArray.length}) // it does not matter if it successfully disconnected
                if(disconnectedAnalytic) setDisconnectedConnectionsCount(disconnectedAnalytic.value);
                await Promise.allSettled(promiseArray)
            } catch {
                showErrorToast({message: 'There was a problem disconnecting your connections.'})
            }
         }
        const filters = connections.length ? {connectionIds: connections.map(connection => connection._id)} :
                            {...getMappedParameters(undefined, connectionsFilter)}
        const result = await removeConnections({filters: filters}, removeFromLD, allSelected);
        if(result?.success) await getConnectionsHandler();
    }

    const toggleHideConnectionHandler = async (connection: IConnection, value: boolean) => {
        if(!connection) return undefined
        const result = await updateConnection(
            connection._id,
            { isHidden: value }
        )
        if(result) setConnections(connections.map((connection: IConnection) => {
            if (connection._id === result._id) {
                return value ? null : { ...result, users: connection.users, autoTagsArray: connection.autoTagsArray }
            }
            return connection;
        }).filter(Boolean))
        return result
    }

    const toggleBulkHideConnectionsHandler = async (selectedConnections:IConnection[] = [], value: boolean) => {
        const filters = selectedConnections.length ? {connectionIds: selectedConnections.map(connection => connection._id)} :
                            {...getMappedParameters(undefined, connectionsFilter)}

        const result = await toggleBulkHideConnections({hideConnection: value, filters: filters})
            if(result.success) {
                await getConnectionsHandler()
                selectedConnections.length === 1 ?
                showSuccessToast({message: value ? 'Connection hidden.' : 'Connection unhidden.'}) :
                showSuccessToast({message: value ? 'Connections hidden.' : 'Connections unhidden.'})
            }
            else {
                showErrorToast({message: value ? 'There was a problem hidding your connections.' : 'There was a problem unhidding your connections.'})
            }
    }

    const exportToCSVHAndler = async (includeTags: boolean, includeNotes: boolean, selectedConnections:IConnection[] = []) => {
        const filters = selectedConnections.length ? {connectionIds: selectedConnections.map(connection => connection._id)} :
                                {...getMappedParameters(undefined, connectionsFilter), isExcludeMode: true}
        const result = await exportToCSV({includeTags: includeTags, includeNotes: includeNotes, filters: filters})
            if(result.success) {
                showSuccessToast({message:`${result.message}`})
            }
            else {
                showErrorToast({message: 'There was a problem downloading connections CSV.'})
            }
    }

    const getMappedParameters = (connectionsParameters: any, connectionsFilter: any): IConnectionsQueryParams => {
        const pagination = connectionsParameters ? { 
            page: connectionsParameters.page || 1,
            pageSize: connectionsParameters.pageSize || 50 } : {}
        return  {
            ...pagination,
            languageCondition: connectionsFilter.languageCondition || 'And',
            tagCondition: connectionsFilter.tagCondition || 'And',
            ...connectionsFilter?.selectedTags && { tags: connectionsFilter?.selectedTags.map((tag: any) => tag._id), tagCondition: connectionsFilter?.tagCondition },
            ...connectionsFilter?.selectedExcludeTags && { excludeTags: connectionsFilter?.selectedExcludeTags.map((tag: any) => tag._id), tagCondition: connectionsFilter?.tagCondition },
            ...connectionsFilter.hiddenConnectionsValue !== 'no' && { isHidden: connectionsFilter.hiddenConnectionsValue.value },
            ...connectionsFilter.contactInfo?.value !== 'all' && { contactInfo: connectionsFilter.contactInfo.value },
            ...connectionsFilter.note?.value !== 'all' && { note: connectionsFilter.note.value },
            ...connectionsFilter.industry?.value !== '' && { industry: connectionsFilter.industry.value },
            ...connectionsFilter.connectedAtFrom !== undefined && { connectedAtFrom: connectionsFilter.connectedAtFrom.toString() },
            ...connectionsFilter.connectedAtTo !== undefined && { connectedAtTo: connectionsFilter.connectedAtTo.toString() },
            ...connectionsFilter.lastMessagedFrom !== undefined && { lastMessagedFrom: connectionsFilter.lastMessagedFrom.toString() },
            ...connectionsFilter.lastMessagedTo !== undefined && { lastMessagedTo: connectionsFilter.lastMessagedTo.toString() },
            ...connectionsFilter.conversationStatus !== undefined && { conversationsStarted: connectionsFilter.conversationStatus === 'conversationsStarted' ? 'yes' : undefined },
            ...connectionsFilter.conversationStatus !== undefined && { lastMessageOutgoing: connectionsFilter.conversationStatus === 'lastMessageOutgoing' ? 'yes' : undefined },
            ...connectionsFilter.conversationStatus !== undefined && { lastMessageIncoming: connectionsFilter.conversationStatus === 'lastMessageIncoming' ? 'yes' : undefined },
            ...connectionsFilter.conversationStatus !== undefined && { noConversationRecorded: connectionsFilter.conversationStatus === 'noConversationRecorded' ? 'yes' : undefined },
            ...connectionsFilter.conversationOwners !== undefined && { conversationOwners: connectionsFilter.conversationOwners.filter((item: any) => item.id !== 'all').map((item: any) => item.id).join(',') },
            ...connectionsFilter.birthDateFrom !== undefined && { birthDateFrom: connectionsFilter.birthDateFrom.toString()},
            ...connectionsFilter.birthDateTo !== undefined && { birthDateTo: connectionsFilter.birthDateTo.toString() },
            ...connectionsFilter.searchByFollowers !== undefined  && { searchByFollowers: connectionsFilter.searchByFollowers },
            ...connectionsFilter.searchByMaxFollowers !== undefined  && { searchByMaxFollowers: connectionsFilter.searchByMaxFollowers },
            ...connectionsFilter?.selectedLanguages && connectionsFilter?.selectedLanguages.length && { languages: connectionsFilter?.selectedLanguages, languageCondition: connectionsFilter?.languageCondition },
            ...(connectionsFilter?.searchValues.length && connectionsFilter?.searchValues[0]?.searchValue) && { searchValues: encodeURIComponent(JSON.stringify(connectionsFilter.searchValues)) },
            ...connectionsFilter?.selectedUsersMutual?.length && { usersMutual: connectionsFilter?.selectedUsersMutual.map((user: any) => user._id).join(','), usersMutualCondition: connectionsFilter?.usersMutualCondition },
            ...connectionsFilter.noTags && connectionsFilter.noTags && { noTags: connectionsFilter.noTags },
            ...connectionsFilter.sort !== '' && { sort: connectionsFilter.sort },
            ...connectionsFilter.autotagsFilter !== undefined && { autotagsFilter: connectionsFilter.autotagsFilter.map((tag: any) => tag._id).join(','), isAutotagsFilterOr: connectionsFilter?.isAutotagsFilterOr ?? 'No'},
        } as IConnectionsQueryParams;
    }

    const numberOfConnectionsFiltersHandler = () => {
        let numberOfFilters = 0

        if(connectionsFilter?.selectedTags.length) numberOfFilters += 1
        if(connectionsFilter?.selectedExcludeTags.length) numberOfFilters += 1
        if(connectionsFilter?.contactInfo?.value && connectionsFilter?.contactInfo?.value !== 'all') numberOfFilters += 1
        if(connectionsFilter?.note?.value && connectionsFilter?.note?.value !== 'all') numberOfFilters += 1
        if(connectionsFilter?.industry && connectionsFilter?.industry !== '' && connectionsFilter?.industry?.value && connectionsFilter?.industry?.value !== '') numberOfFilters += 1
        if((connectionsFilter?.connectedAtFrom && connectionsFilter?.connectedAtFrom !== '') || (connectionsFilter?.connectedAtTo && connectionsFilter?.connectedAtTo !== '')) numberOfFilters += 1
        if((connectionsFilter?.lastMessagedFrom && connectionsFilter?.lastMessagedFrom !== '') || (connectionsFilter?.lastMessagedTo && connectionsFilter?.lastMessagedTo !== '')) numberOfFilters += 1
        if((connectionsFilter?.birthDateFrom && connectionsFilter?.birthDateFrom !== '') || (connectionsFilter?.birthDateTo && connectionsFilter?.birthDateTo !== '')) numberOfFilters += 1
        if((connectionsFilter?.searchByFollowers !== undefined && connectionsFilter?.searchByFollowers !== '' ) || (connectionsFilter?.searchByMaxFollowers !== undefined && connectionsFilter?.searchByMaxFollowers !== '')) numberOfFilters += 1
        if(connectionsFilter?.selectedLanguages.length) numberOfFilters += 1
        if(connectionsFilter?.selectedUsersMutual.length) numberOfFilters += 1
        if(connectionsFilter?.noTags) numberOfFilters += 1
        if(connectionsFilter?.autotagsFilter) numberOfFilters += 1
       // if(connectionsFilter?.sort) numberOfFilters += 1
        if(connectionsFilter?.hiddenConnectionsValue?.value && connectionsFilter?.hiddenConnectionsValue?.value !== 'no') numberOfFilters += 1

        return numberOfFilters
    }

    const resetConnectionsStoreHandler = () => {
        resetConnectionsStore();
    }

    const setConnectionsPageSize = (pageSize: Number) => {
        setConnectionsParametersHandler({
            ...connectionsParameters,
            pageSize: pageSize
        })
    }

    const getWorkspaceSyncStatusHandler = async (workspace: string) => {
        const result = await getWorkspaceSyncStatus(workspace);
        console.log(result);
        return result;
    }

    return {
        getConnectionsHandler,
        setClearConnectionsFilterHandler,
        setConnectionsFilterHandler,
        getMultipleConditionsFiltersHandler,
        setMultipleConditionsFiltersHandler,
        applyRemoveTagsToConnection,
        bulkApplyTags,
        bulkRemoveTags,
        getConnectionByProfileIdHandler,
        updateConnectionHandler,
        setConnectionsParametersHandler,
        sendMessageToConnectionsHandler,
        followProfileHandler,
        unfollowProfileHandler,
        toggleHideConnectionHandler,
        exportToCSVHAndler,
        toggleBulkHideConnectionsHandler,
        numberOfConnectionsFiltersHandler,
        disconnectConnectionHandler,
        bulkDisconnectConnectionHandler,
        setConnectionsPageSize,
        resetConnectionsStoreHandler,
        getWorkspaceSyncStatusHandler
    };
}
