import { FunctionComponent, useState, useEffect, ReactNode } from 'react';
import styled from 'styled-components';
import { styles } from '../styles/themes/style';
import DropdownMenu from '../components/dropdown/DropdownMenu';
import DropdownItem from '../components/dropdown/DropdownItem';
import { ITabProperties } from '../components/TabsView';
import Row from '../components/Row';
import Checkbox from '../components/Checkbox';
import Calendar from 'react-calendar';
import Column from '../components/Column';
import FilterDropdownButton from './dropdowns/FilterDropdownButton';
import Text from '../components/Text';
import DropdownSeparator from '../components/dropdown/DropdownSeparator';
import moment from 'moment';
import Tooltip from './Tooltip';
import TooltipWrapper from '../components/TooltipWrapper';
import SvgFragment from './SvgFragment';

export interface ILastMessagedFilterFragmentOption {
    value: string,
    label: ReactNode,
    id: string,
    selected: boolean,
    object: Object
}

interface ILastMessagedFilterFragmentProps {
    children?: React.ReactNode;
    drop?: string
    filterOptions: ILastMessagedFilterFragmentOption[];
    selectedItems: any[];
    inputTitle: string;
    showSearchField?: boolean;
    inputSelect?: boolean;
    resetDisabled?: boolean;
    lastMessagedFrom?: any;
    lastMessagedTo?: any;
    isBusinessWs?: boolean;
    onSelectedOwnerHandler: (control: any, newValue: any) => void;
    onSelectedDateHandler: (control: any, newValue: any) => void;
    onStatusChangeHandler: (control: any, newValue: any) => void;
    resetFilter: () => void;
}

const LastMessagedFilterFragment: FunctionComponent<ILastMessagedFilterFragmentProps> = ({ drop, filterOptions, selectedItems, inputTitle, lastMessagedFrom, lastMessagedTo, isBusinessWs=true, onSelectedOwnerHandler, onSelectedDateHandler, onStatusChangeHandler, resetFilter }) => {
 
    const [selectOptions, setSelectOptions] = useState<ILastMessagedFilterFragmentOption[]>([]);
    const [dateFrom, setDateFrom] = useState(lastMessagedFrom);
    const [dateTo, setDateTo] = useState(lastMessagedTo);
    const [selectedNames, setSelectedNames] = useState<string>('');
    const [selectedStatus, setSelectedStatus] = useState<string>('');
    const [selectedAction, setSelectedAction] = useState<string | null>(null);

    const upgradePlanTooltip = 'Upgrade your plan to unlock this feature';

    const dropdownButton = <FilterDropdownButton
        svgType='email' 
        placeholder={dateFrom || dateTo ? <>
            <Text $black>{dateFrom ? moment(dateFrom).format('MMM D, YYYY') : 'From Date'}</Text>
            - 
            <Text $black>{dateTo ? moment(dateTo).format('MMM D, YYYY') : 'To Date'}</Text>
            </> : <>{ inputTitle }</>}
        resetFilter={(dateFrom || dateTo) ? () => resetFilter() : undefined}
        />

    const dropdownButtonMembers = <FilterDropdownButton
        placeholder={selectedNames || 'All members'}
        />

    const dropdownButtonStatus = <FilterDropdownButton
        placeholder={selectedStatus || 'All conversations'}
        />

    useEffect(() => {
        if(!selectOptions.length && filterOptions.length){
            setSelectOptions([{id: 'all', label: 'All Members', selected: false, value: 'all', object: {}}, ...filterOptions?.map((option) => ({...option, selected: selectedItems?.some((item) => item._id === option.id)}))]);
        }
    }, [filterOptions]);

    useEffect(() => {
        setDateFrom(lastMessagedFrom);
    }, [lastMessagedFrom]);
 
    useEffect(() => {
        setDateTo(lastMessagedTo);
    }, [lastMessagedTo]);

    const toggleItemHandler = (event: any, item: ILastMessagedFilterFragmentOption) => {
        event.stopPropagation()
        let add: boolean
        let allSelected = false;
        let selectedOwnersObj: any = [];
        if(item.id === 'all'){
            add = !item.selected
            setSelectOptions(selectOptions.map((option) => {
                if (add){
                    selectedOwnersObj.push(option);
                    allSelected = true;
                }   
                return {...option, selected: add}
            }))
        } else {
            setSelectOptions(selectOptions.map((option) => {
                if(option.id === item.id) {
                    add = !option.selected
                    let newOption = {...option, selected: add}
                    if(newOption.selected) {
                        selectedOwnersObj.push(option);
                    }
                    return newOption;
                } else if(option.selected) {
                    selectedOwnersObj.push(option);
                }
                if(item.selected){
                    allSelected = false;
                }
                return {...option, selected: (option.id === 'all' && item.selected) ? false : option.selected}
            }))
        }

        if(!allSelected){ // remove 'all' if not all members are selected
            selectedOwnersObj = selectedOwnersObj.filter((item: any) => item.id !== 'all');
        }

        if(item.id !== 'all'){
            if (selectedOwnersObj.filter((item: any) => item.id !== 'all').length <= 1) {
                setSelectedNames(selectedOwnersObj.filter((item: any) => item.id !== 'all').map((o: any) => o.value).join(', '));
              } else {
                setSelectedNames(`Members: ${selectedOwnersObj.filter((item: any) => item.id !== 'all').length} selected`);
              }
        } else {
            setSelectedNames('All members');
        }

       onSelectedOwnerHandler('conversationOwners', selectedOwnersObj.length ? selectedOwnersObj : undefined)
    }

    const dateChangedHandler = (fromOrTo: 'from' | 'to' | 'actionText', event: any) => {
        if (fromOrTo === 'from') {
            onSelectedDateHandler('lastMessagedFrom', event[0]);
        } else if (fromOrTo === 'to') {
            onSelectedDateHandler('lastMessagedTo', event[1]);
        } else {
            setSelectedAction(event?.value);
            if(event?.value === actionValuesObjects[0].value || event?.value === actionValuesObjects[1].value){ // today or yesterday
                const time = moment(event?.date);
                onSelectedDateHandler(['lastMessagedFrom', 'lastMessagedTo'], [time.startOf('day').toDate(), time.endOf('day').toDate()]);
            } else {
                const firstDayOfMonth = moment(event?.date);
                const lastDayOfMonth = firstDayOfMonth.endOf('month').toDate();
                onSelectedDateHandler(['lastMessagedFrom', 'lastMessagedTo'], [event?.date, lastDayOfMonth]);
            }
        }
    };

    const onConversationStatusChangeHandler = (control: any, newValue: any) => {
        const statusLabel = statusOptions.find((item: any) => item?.value === newValue)?.label;
        setSelectedStatus(statusLabel || '');
        onStatusChangeHandler && onStatusChangeHandler(control, newValue);
    }

    const statusOptions = [
        { value: 'all', label: 'All conversations' },
        { value: 'conversationsStarted', label: 'Conversation started' },
        { value: 'lastMessageOutgoing', label: 'Last message outgoing' },  
        { value: 'lastMessageIncoming', label: 'Last message incoming' },  
        { value: 'noConversationRecorded', label: 'No conversation recorded' },  
    ];

    const actionValuesObjects = [
        {
            value: 'Today',
            date: moment().add(0, 'day').toDate()
        },
        {
            value: 'Yesterday',
            date: moment().add(-1, 'day').toDate()
        },
        {
            value: 'This month',
            date: moment().startOf('month').toDate()
        },
        {
            value: 'Last month',
            date: moment().subtract(1, 'month').startOf('month').toDate()
        }   
    ]

    return (
            <DropdownMenu 
                    title={dropdownButton}
                    autoClose='outside'
                    drop={drop}
                    selectStyle
                    $hideToggle={dateFrom || dateTo}
                >   
                <DropdownItem $nonHoverable>
                    <Row>
                        <Actions>
                            {actionValuesObjects.map((v: any, index: number) => 
                                <ActionText 
                                    isSelected={selectedAction === v.value} 
                                    key={index} 
                                    onClick={() => dateChangedHandler('actionText', v)}>{v.value}
                                </ActionText>)}
                        </Actions>
                    </Row>
                    <DropdownSeparator />
                    <Row>
                        <LeftSide>
                            <Label>From Date</Label>
                            <StyledCalendar
                                value={!dateTo ? dateFrom : [dateFrom, dateTo]}
                                onChange={event => dateChangedHandler('from', event as Date[])}
                                showNeighboringMonth={false}
                                minDate={new Date(1900, 4, 5)}
                                maxDate={dateTo || new Date()}
                                returnValue='range'
                                defaultActiveStartDate={!dateFrom ? new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate()) : dateFrom}
                                locale='en-US' 
                            />
                        </LeftSide>
                        <RightSide>
                            <Label>To Date</Label>
                            <StyledCalendar
                                value={!dateTo ? null : [dateFrom, dateTo]}
                                onChange={event => dateChangedHandler('to', event as Date[])}
                                showNeighboringMonth={false}
                                minDate={dateFrom || new Date(1900, 4, 5)}
                                maxDate={new Date(2050, 4, 5)}
                                returnValue='range'
                                defaultActiveStartDate={dateTo && dateTo}
                                locale='en-US' 
                            />
                        </RightSide>
                    </Row>
                    <DropdownSeparator />
                    <Row alignItems spaceBetween>
                        <StyledColumnLeft
                            onClick={(e) => {
                                e.stopPropagation();
                            }}>
                            <TitleContainer>
                                <Label isBusiness={isBusinessWs}>Conversation status</Label>
                                {!isBusinessWs && <SvgFragment type='lock'/>}
                            </TitleContainer>
                            <StyledDropdownMenu
                                title={isBusinessWs ? dropdownButtonStatus : <StyledTitleContainer>{'All conversations'}</StyledTitleContainer>}
                                drop='down'
                                selectStyle
                                disabled={!isBusinessWs}
                                tooltipMessage={!isBusinessWs ? upgradePlanTooltip : ''}
                                isBusiness={isBusinessWs}
                            >  
                                    {statusOptions.map((item: any) => (
                                        <DropdownItem
                                            key={item.id}
                                            onClickHandler={(e: any) => onConversationStatusChangeHandler('conversationStatus', item.value)}>
                                            <Row alignItems gap="10px">
                                                <Column>
                                                    {<Text>{item.label}</Text>}
                                                </Column>
                                            </Row>
                                    </DropdownItem>
                                    ))}
                            </StyledDropdownMenu>
                        </StyledColumnLeft>
                        <StyledColumnRight>
                            <TitleContainer>
                                <Label isBusiness={isBusinessWs}>Conversation owner</Label>
                                {!isBusinessWs && <SvgFragment type='lock'/>}
                            </TitleContainer>
                            <StyledDropdownMenu
                                title={isBusinessWs ? dropdownButtonMembers : <StyledTitleContainer>{'All members'}</StyledTitleContainer>}
                                autoClose='outside'
                                drop='down'
                                selectStyle
                                disabled={!isBusinessWs}
                                tooltipMessage={!isBusinessWs ? upgradePlanTooltip : ''}
                                isBusiness={isBusinessWs}
                            >  
                                    {selectOptions.map((item: any) => (
                                        <DropdownItem
                                            key={item.id}
                                            onClickHandler={(e: any) => toggleItemHandler(e, item)}>
                                            <Row alignItems gap="10px">
                                                <Checkbox checked={item.selected} checkboxId={item.id} />
                                                {item.label}
                                            </Row>
                                    </DropdownItem>
                                    ))}
                            </StyledDropdownMenu>
                        </StyledColumnRight>
                    </Row>
                </DropdownItem>
            </DropdownMenu>
    );
}

const StyledDropdownMenu = styled(DropdownMenu)<{ isBusiness?: boolean }>`
    background-color: ${({isBusiness, theme: { secondaryColor }}) => isBusiness ? secondaryColor : styles.colors.white} !important;
    .actions-dropdown + div {
        margin-bottom: 13px;
        width: 100%;
        max-height: 350px;
        overflow-x: hidden !important;
            
    }

    .actions-dropdown > span {
         color:  ${styles.colors.disabledState} !important;
    }

   
    
     .dropdown-toggle {
            
            &:hover, &:focus {
            
                outline: 2px solid ${({isBusiness}) => isBusiness ? styles.colors.primary500 : styles.colors.disabledState}
        },
     }
`

const StyledColumnLeft = styled(Column)`
    width: 100%;
    margin-top: 6px;
    margin-bottom: 20px;
    margin-right: 15px;
`;

const StyledColumnRight = styled(Column)`
    width: 100%;
    margin-top: 6px;
    margin-bottom: 20px;
    margin-left: 15px;
`;

const Side = styled.div`
    height: 232px;
    width: 240px;
    display: inline-block;
    vertical-align: top;
`;

const LeftSide = styled(Side)`
    margin-right: 27px;
`;

const RightSide = styled(Side)``;

const TitleContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 4px;
    align-items: center;
    margin-bottom: 8px;
`

const StyledTitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    height: 34px;
    border: none;
    width: 100%;
    border-radius: 4px;
    cursor: pointer;
    color: ${styles.colors.disabledState}
`

const Label = styled.p<{ isBusiness?: boolean }>`
    line-height: 16px;
    font-family: NunitoSans;
    font-size: 11px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${({isBusiness}) => isBusiness ? styles.colors.black600 : styles.colors.black300 };
    margin: 0;
`;

const StyledCalendar = styled(Calendar)`
    height: 204px;
    width: 240px;
    font-family: NunitoSans;
    font-size: 14px;
    font-weight: bold;
    color: ${styles.colors.black600};

    & abbr { text-decoration: none; }

    & button {
        border: none;
        background-color: transparent;

        &:disabled, &:disabled:hover { color: ${props => props.theme.primary ? '#b8bfcf' : '#7e8087'}; }
        &:not(:disabled) {
            color: ${props => props.theme.primary ? '#333951' : '#E8E8E8'};
            cursor: pointer;
        }       
    }

    & .react-calendar__navigation {
        height: 20px;
        margin-bottom: 10px;
        display: flex;
    }

    & .react-calendar__navigation__label {
        font-weight: bold;
        color: ${styles.colors.black600};
        display: flex;
        justify-content: center;
    }

    & .react-calendar__navigation__arrow {
        line-height: 20px;
        font-size: 20px;
        font-weight: bold;
        color: #8c96ad;
    }

    & .react-calendar__viewContainer {
        height: 174px;
    }

    & .react-calendar__month-view__weekdays {
        height: 20px;
        margin-bottom: 4px;
        align-items: center;
    }

    & .react-calendar__month-view__weekdays__weekday {
        text-align: center;
    }

    & .react-calendar__month-view__days {
        height: 150px;
    }

    & .react-calendar__tile {
        height: 28px;
        width: 33px;
        margin-bottom: 2px;

        &:hover {color: #5964FF;}
    }

    & .react-calendar__tile--active {        
        background-color: ${props => props.theme.primary ? '#eef3ff' : '#373945'};
        color: ${props => props.theme.primary ? '#333951 !important' : '#E8E8E8 !important'};

        &:hover { background-color: ${props => props.theme.primary ? '#F6FBFF' : '#2A2D3A'}; }
    }

    & .react-calendar__tile--rangeStart, & .react-calendar__tile--rangeEnd {
        border-radius: 6px;
        background-color: #5964ff;
        color: ${props => props.theme.primary ? 'white !important' : '#E8E8E8 !important'};

        &:hover {
            background-color: #7a89fc;
            color: ${props => props.theme.primary ? '#333951' : '#E8E8E8'};
        }
    }
`

const Actions = styled.div`
    display: flex;
    width: 100%;
    height: 36px;
    gap: 10px;
    align-items: center;
`

const ActionText = styled.span<{ isSelected: boolean }>`
    color: ${styles.colors.primary600};
    background-color: #EEF3FA;
    font-family: NunitoSans;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    cursor: pointer;
    padding: 2px 4px;
    border-radius: 4px;
    border: 1px solid transparent;
    &:hover { border: 1px solid #5964FF !important; }
    ${({ isSelected }) =>
        isSelected && `border: 1px solid ${styles.colors.primary600};`}
`

export default LastMessagedFilterFragment;
