import { FunctionComponent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Column from '../../../components/Column';
import TaskControlBar from '../components/tasks/TaskControlBar';
import TasksTable from '../components/tasks/TasksTable';

interface ITaskSectionProps { }

const TaskSection: FunctionComponent<ITaskSectionProps> = () => {
    const [controlBarHeight, setControlBarHeight] = useState<number>(0);
    const controlBarRef = useRef<HTMLDivElement>(null);

    const updateControlBarHeight = () => {
        if (controlBarRef.current) {
            const height = controlBarRef.current.offsetHeight - 2;
            setControlBarHeight(height);
        }
    };
    useEffect(() => {
        updateControlBarHeight();
    }, [controlBarRef.current]);

    return <Container>
        <Column>
            <StickyControlBar ref={controlBarRef}>
                <TaskControlBar onProgressHidden={() => updateControlBarHeight()}/>
            </StickyControlBar>
            <TasksTable controlBarHeight={controlBarHeight} />
        </Column>
    </Container>
}

const Container = styled.div`
    width: 100%;
    padding: 0px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    overflow-y: auto;
`

const StickyControlBar = styled.div`
  position: sticky;
  top: 0;
  z-index: 101;
  background: ${({theme: { tertiaryColor }}) => tertiaryColor};
  padding-top: 16px;
`;

export default TaskSection
