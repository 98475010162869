import { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import Row from "../components/Row";
import { useAuthStore } from "../stores/auth/useAuthStore";
import { useAuthHandlers } from "../services/actions/auth/useAuthHandlers";
import Text from "../components/Text";
import LoadingSpinner from "../components/LoadingSpinner";
import SvgFragment from "./SvgFragment";
import { styles } from "../styles/themes/style";
import { useConnectionsHandlers } from "../services/actions/connections/useConnectionsHandlers";

interface IConnectionsSyncProgress {
  onProgressShown?: () => void;
  onProgressHidden?: () => void;
}

const MAX_CONNECTIONS = 1000;
const MESSAGE_DURATION = 5000;

const ConnectionsSyncProgress: FunctionComponent<IConnectionsSyncProgress> = ({
  onProgressShown,
  onProgressHidden,
}) => {
  const {
    store: { syncInProgress, workspace },
  } = useAuthStore();
  const { setSyncInProgressHandler } = useAuthHandlers();
  const { getWorkspaceSyncStatusHandler } = useConnectionsHandlers();

  const [numOfConn, setNumOfConn] = useState(0);
  const [showCompletedMessage, setShowCompletedMessage] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (syncInProgress) {
      setIsVisible(true);
      setShowCompletedMessage(false);
      onProgressShown && onProgressShown();
      interval = setInterval(async () => {
        try {
          const response = await getWorkspaceSyncStatusHandler(workspace);

          setNumOfConn(response.total);
          setSyncInProgressHandler(response.isImporting);

          if (!response.isImporting) {
            clearInterval(interval);
            setShowCompletedMessage(true);
          }
        } catch (error) {
          console.error("Error fetching sync status:", error);
          clearInterval(interval);
        }
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [
    syncInProgress,
    workspace,
    setSyncInProgressHandler,
    getWorkspaceSyncStatusHandler,
  ]);

  useEffect(() => {
    if (showCompletedMessage) {
      const timeout = setTimeout(() => {
        setIsVisible(false);
      }, MESSAGE_DURATION);

      return () => clearTimeout(timeout);
    }
  }, [showCompletedMessage]);

  if (!isVisible) {
    onProgressHidden && onProgressHidden();
    return null;
  }

  return (
    <Wrapper>
      {syncInProgress && (
        <Row gap="8px" alignItems>
          <Text $bold>Updating connections</Text> ({numOfConn} connections
          updated) <LoadingSpinner />
        </Row>
      )}
      {!syncInProgress && showCompletedMessage && (
        <Row gap="8px" alignItems>
          <Text $bold>{numOfConn} connections successfully updated.</Text>
          <CheckMark>
            <SvgFragment type="checkmark" />
          </CheckMark>
        </Row>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Row)`
  background: transparent;
  padding: 0px 0px 16px 0px;
`;

const CheckMark = styled.div`
  border-radius: 50%;
  background-color: ${styles.colors.primary600};
  width: 16px;
  height: 16px;
  display: flex;
  margin-right: 10px;

  .svg {
    .path {
      fill: ${styles.colors.white};
    }
  }
`;

export default ConnectionsSyncProgress;
