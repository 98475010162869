import { FunctionComponent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Column from '../../../components/Column';
import TemplatesControlBar from '../components/templates/TemplatesControlBar';
import { useTemplateHandlers } from '../../../services/actions/template/useTemplateHandlers';
import TemplatesTable from '../components/templates/TemplatesTable';
import { useTemplateStore } from '../../../stores/templates/useTemplateStore';

interface ITemplatesSectionProps { }

const TemplatesSection: FunctionComponent<ITemplatesSectionProps> = () => {
    const { getTemplatesHandler } = useTemplateHandlers()
    const { store: { templates, templatesParameters, templatesFilters } } = useTemplateStore()
    const [controlBarHeight, setControlBarHeight] = useState<number>(0);
    const controlBarRef = useRef<HTMLDivElement>(null);

    const updateControlBarHeight = () => {
        if (controlBarRef.current) {
            const height = controlBarRef.current.offsetHeight - 2;
            setControlBarHeight(height);
        }
    };
    useEffect(() => {
        updateControlBarHeight();
    }, [controlBarRef.current]);

    useEffect(() => {
        getTemplatesHandler()
    }, [templatesParameters.page, templatesParameters.pageSize, templatesFilters])

    return (
        <Container>
            <Column gap='20px'>
                <StickyControlBar ref={controlBarRef}>
                    <TemplatesControlBar onProgressHidden={() => updateControlBarHeight()} />
                </StickyControlBar>
                <TemplatesTable templates={templates} controlBarHeight={controlBarHeight} /> 
            </Column>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    padding: 0px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    overflow-y: auto;
`

const StickyControlBar = styled.div`
  position: sticky;
  top: 0;
  z-index: 101;
  background: ${({theme: { tertiaryColor }}) => tertiaryColor};
  padding-top: 16px;
`;

export default TemplatesSection
