import React, { FormEvent, FunctionComponent, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { IConnection } from '../interfaces/models/connection'
import ProfilePhoto from '../components/ProfilePhoto'
import Text from '../components/Text'
import Row from '../components/Row'
import Button from '../components/Button'
import Column from '../components/Column'
import moment from 'moment'
import ConnectionActionDropdown from './dropdowns/ConnectionActionDropdown'
import ApplyRemoveTagForm from './forms/ApplyRemoveTagForm'
import ActionText from '../components/ActionText'
import { sanitize } from '../utils'
import { styles } from '../styles/themes/style'
import EditDeleteActionDropdown from './dropdowns/EditDeleteActionDropdown'
import { ICreateNote, useNotesHandlers } from '../services/actions/notes/useNotesHandlers'
import { useAppHandlers } from '../services/actions/app/useAppHandlers'
import CreateEditNoteForm from './forms/CreateEditNoteForm'
import { useTasksHandlers } from '../services/actions/tasks/useTasksHandlers'
import { ITaskResponse } from '../interfaces/response/task'
import CreateEditTaskForm from './forms/CreateEditTaskForm'
import AppTag from './AppTag'
import { useAuthStore } from '../stores/auth/useAuthStore'
import { useExternalHandlers } from '../services/actions/navigation/useExternalHandlers'


interface IConnectionSidebarFragmentProps {
    connection: IConnection
 }

const ConnectionSidebarFragment: FunctionComponent<IConnectionSidebarFragmentProps> = ({connection}) => {

    const [currentConnection, setCurrentConnection] = useState<IConnection>(connection)
    const { getConnectionTasks, createTaskHandler, removeTaskHandler, updateTaskHandler } = useTasksHandlers()
    const { setDestructiveModal, hideModal } = useAppHandlers()
    const { store: { workspace: { showAutoTags }}} = useAuthStore()
    const { visitLIProfile } = useExternalHandlers()

    const [connectionProperties, setConnectionProperties] = useState<boolean>(false)
    const [editTags, setEditTags] = useState<boolean>(false)
    const [submitConnections, setSubmitConnections ] = useState<any>(0)
    const [addTask, setAddTask] = React.useState(false);
    const [addingTask, setAddingTask] = React.useState(false);
    const [tasks, setTasks ] = useState<ITaskResponse[]>([])
    const [editingTask, setEditingTask] = useState<any>();
    const [addingTag, setAddingTag] = React.useState(false);

    const submitConnectionsTagsPressed = useCallback(() => {
        setSubmitConnections(moment())
    }, [submitConnections])

    const cancelConnectionTagsHandler = useCallback(() => {
        setSubmitConnections(0)
        setEditTags(false)
        hideModal()
    }, [submitConnections])

    const submitConnectionsTagsHandler = useCallback((newConnection: IConnection) => {
        if(newConnection) setCurrentConnection({ ...newConnection, ...!showAutoTags ? { tags: newConnection.tags?.filter(tag => !tag.isForbidden) } : {}})
        setSubmitConnections(0)
        setEditTags(false)
        hideModal()
    }, [currentConnection])

    useEffect(() => {
            loadTasksHandler();
    }, [currentConnection])

    useEffect(() => {
        if(!showAutoTags) setCurrentConnection({...currentConnection, tags: currentConnection.tags.filter(tag => !tag.isForbidden)})
    }, [showAutoTags])

    const loadTasksHandler = useCallback(async () => {
            let tasks = await getConnectionTasks(currentConnection._id);
            setTasks(tasks);
    }, []);

    const onCloseTaskFormHandler = async () => {
        setAddTask(false); 
        setEditingTask(undefined);
    }

    const onCreateUpdateTaskHandler = async (taskId: string | null, task: any) => {
        setAddTask(false); 
        setAddingTask(true);
        taskId ? await updateTaskHandler(taskId, task) : await createTaskHandler(task);
        loadTasksHandler();
        setAddingTask(false); 
        setEditingTask(undefined);
    }

    const onDeleteTaskHandler = async (taskId: string) => {
        setDestructiveModal({
            headingText: "Delete task",
            descriptionText: "Are you sure you want to delete this task?",
            onCancelHandler: () => hideModal(),
            onConfirmHandler: async () => {
                hideModal()
                deleteTaskConfirmed(taskId)
            }
        })
    }

    const deleteTaskConfirmed = async (taskId: string) => {
        await removeTaskHandler(taskId);
        loadTasksHandler();
    }

    const onEditTaskHandler = async (task: any) => {
        setEditingTask(task);
        setAddTask(true);
    }

    const onChangeTagsHandler = async () => {
        setAddingTag(true);
    }

    return <Container>
        <StylesProfilePhoto source={currentConnection.profilePicture} />
        <section>
            <Text $bold $black $heading4>{`${currentConnection.firstName} ${currentConnection.lastName}`}</Text>
            <Text $black>{currentConnection.headline}</Text>
        </section>
        <UpdateVisitProfileSection gap="10px">
             <Button $type="grey" $SVGtype="profile" onClickHandler={() => currentConnection.publicIdentifier && visitLIProfile(currentConnection.publicIdentifier) } disabled={!currentConnection.publicIdentifier} >
                {'Visit Profile'}
            </Button>
            <ConnectionActionDropdown connection={currentConnection} />
        </UpdateVisitProfileSection>
            <Column gap='16px'>
                <Column>
                    <Text $label $black>Contact info</Text>
                    <Text $black>{currentConnection.email || 'Not disclosed'}</Text>
                    <Text $black>{currentConnection.phoneNumber || 'Not disclosed'}</Text>
                </Column>
                {connectionProperties &&
                    <Column gap="16px">
                        <Column>
                            <Text $label $black>Company</Text>
                            <Text $black>{currentConnection.company || 'Not disclosed'}</Text>
                        </Column>
                        <Column>
                            <Text $label $black>Location</Text>
                            <Text $black>{currentConnection.country || 'Not disclosed'}</Text>
                        </Column>
                        <Column>
                            <Text $label $black>Industry</Text>
                            <Text $black>{currentConnection.industry || 'Not disclosed'}</Text>
                        </Column>
                        <Column>
                            <Text $label $black>Date Connected</Text>
                            <Text $black>{moment(currentConnection.connectedAt).format('MMM D, YYYY [at] HH:mm')}</Text>
                        </Column>
                        <Column>
                            <Text $label $black>Number of followers</Text>
                            <Text $black>{currentConnection.followerCount?.toLocaleString() || 'Not disclosed'}</Text>
                        </Column>
                    </Column>
                }
                <Button onClickHandler={() => setConnectionProperties(!connectionProperties)}>
                    {connectionProperties ? 'Less' : 'More'} info
                </Button>
            </Column>
            <Column gap='10px'>
                <Row spaceBetween alignItems>
                    <Text $heading6>{'Tasks'}</Text>
                    {!addTask && tasks && tasks.length > 0 &&
                    <ActionText 
                        onClickHandler={() => setAddTask(true)} 
                        $disabled={addingTask}>{'Add task'}</ActionText>}
                </Row>

                {addTask && !editingTask && (<CreateEditTaskForm
                                task={editingTask}
                                formType={'short'}
                                connection={currentConnection}
                                onCreateHandler={(taskId: string | null, task: any) => onCreateUpdateTaskHandler(taskId, task)}
                                onCloseHandler={() => onCloseTaskFormHandler()} 
                            />
                )} 
                {tasks && tasks.length > 0 ? (
                    tasks.map((task: any) => {
                            return editingTask && editingTask._id === task._id ? (
                                <CreateEditTaskForm
                                    task={editingTask}
                                    formType={'short'}
                                    connection={currentConnection}
                                    onCreateHandler={(taskId: string | null, task: any) => onCreateUpdateTaskHandler(taskId, task)}
                                    onCloseHandler={() => onCloseTaskFormHandler()} 
                                />
                            ) :  (
                                <StyledNoteRow spaceBetween>
                                    <Column gap='10px'>
                                        <Text $bold $black>{<InnerHtml dangerouslySetInnerHTML={{ __html: sanitize(task.title) }} />}</Text>
                                        <Text $label $lighter>{task.end ? moment.unix(task.end).format("MMM D, YYYY") : 'No due date'}</Text>
                                    </Column>
                                    <EditDeleteActionDropdown
                                        object={task}
                                        type='task'
                                        onDeleteHandler={() => onDeleteTaskHandler(task._id)}
                                        onEditHandler={() => onEditTaskHandler(task)}
                                    />
                                </StyledNoteRow>
                            )
                    })
                ) : (
                    !editingTask && !addTask && !addingTask && (
                        <EmptyListWrapper>
                            <EmptyListLabel>No tasks to show</EmptyListLabel>
                            <EmptyListButton onClick={() => setAddTask(true)}>Add task</EmptyListButton>
                        </EmptyListWrapper>
                        )   
                    )
                }
                <Row spaceBetween alignItems>
                    <Text $heading6>{'Tags'}</Text>
                    {(!editTags && currentConnection?.tags?.length > 0) ? 
                        <ActionText onClickHandler={() => setEditTags(true)}>{'Edit tags'}</ActionText> :
                       ( (currentConnection?.tags?.length > 0 || addingTag) ? (<Row gap="5px">
                            <ActionText cancel $disabled={submitConnections > 0} onClickHandler={() => {cancelConnectionTagsHandler(); setAddingTag(false)}}>{'Cancel'}</ActionText>
                            <ActionText $disabled={submitConnections > 0} onClickHandler={() => submitConnectionsTagsPressed()}>{'Save'}</ActionText>
                        </Row>) : undefined)
                    }
                </Row>
                {(!currentConnection?.tags?.length && !editTags) ? (
                        !editingTask && !addTask && !addingTask && (
                            <EmptyListWrapper>
                                <EmptyListLabel>No tags to show</EmptyListLabel>
                                <EmptyListButton onClick={() => setEditTags(true)}>Add tag</EmptyListButton>
                            </EmptyListWrapper>
                            )
                        )
                    :
                        (
                        editTags ? 
                            <ApplyRemoveTagForm 
                                connection={currentConnection} 
                                tags={!currentConnection ? [] : showAutoTags ? currentConnection?.tags : currentConnection?.tags?.filter(tag => !tag.isForbidden)?.sort((a, b) => a?.title?.localeCompare(b?.title))}
                                inline 
                                submitClicked={submitConnections} 
                                onSaveHandler={submitConnectionsTagsHandler}
                                onChangeHandler={onChangeTagsHandler}/>
                            :
                            <StyledTagRow gap='5px'>{currentConnection.tags?.sort((a, b) => a?.title?.localeCompare(b?.title)).map((tag) => <AppTag
                                    tag={tag}
                                />)}</StyledTagRow>
                    )
                }
                <Column gap='10px'>
                    <CreateEditNoteForm
                        connectionId={currentConnection._id}
                        existingNotes={currentConnection.notes}
                        showEmptyPage={true}
                        addScrollOffset={true}
                    />
                </Column>
            </Column>
        
    </Container>
}

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    gap: 16px;
`

const StylesProfilePhoto = styled(ProfilePhoto)`
    width: 64px;
    height: 64px;
`
const UpdateVisitProfileSection = styled(Row)`
    align-items: center;
`
const StyledNoteRow = styled(Row)`
    width: 100%;
    padding: 10px;
    background: ${styles.colors.black100};
`
const StyledTagRow = styled(Row)`
    text-wrap: nowrap;
    flex-wrap: wrap;
`
const InnerHtml = styled.span`
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`
const EmptyListWrapper = styled.div`
    height: 86px;
    padding 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 4px;
    background-color: ${styles.colors.black100};
`
const EmptyListLabel = styled.p`
    font-weight: 700;
    line-height: 14px;
    font-size: 11px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${styles.colors.black300};
    font-family: NunitoSans;
`
const EmptyListButton = styled.p`
    font-family: NunitoSans;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    cursor: pointer;
    padding: 8px;
    border-radius: 4px;
    color: ${({theme: {secondaryColor}}) => secondaryColor};
    background-color: ${styles.colors.primary600};
`
export default ConnectionSidebarFragment
